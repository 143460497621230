import React, { useEffect, useState } from "react";
import "./youtubeVideo.css";

const YouTubeVideos = () => {
    const [videos, setVideos] = useState([]);
    const channelId = "UCDnHdrxuWJUb8aFkxuUJgCA"; // Dynamic Channel ID
    const channelUrl = `https://www.youtube.com/channel/${channelId}`;

    useEffect(() => {
        const fetchYouTubeVideos = async () => {
            try {
                const response = await fetch(
                    `https://api.rss2json.com/v1/api.json?rss_url=https://www.youtube.com/feeds/videos.xml?channel_id=${channelId}`
                );
                const data = await response.json();
                setVideos(data.items.slice(0, 4)); // Limit to 4 videos
            } catch (error) {
                console.error("Error fetching YouTube videos:", error);
            }
        };
        fetchYouTubeVideos();
    }, [channelId]);

    return (
        <section id="youtube">
            <center><h1 className="y-title">Latest Videos</h1></center>
            <div className="videos-grid">
                {videos.map((video, index) => (
                    <div key={index} className="video-card">
                        <iframe
                            src={`https://www.youtube.com/embed/${video.link.split("v=")[1]}`}
                            title={video.title}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                        <h3>{video.title}</h3>
                    </div>
                ))}
            </div>

            {/* Stay Tuned Button */}
            <div className="button-container">
                <a href={channelUrl} target="_blank" rel="noopener noreferrer" className="stay-tuned-btn">
                    Stay Tuned
                </a>
            </div>
        </section>
    );
};

export default YouTubeVideos;
