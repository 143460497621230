import React from "react";
import "./intro.css";
import IntroImg from "../../images/baseimage.png";

const Intro = () => {
    return (
        <div id="intro" className="i">
            <div className="i-left">
                <div className="i-left-wrapper">
                    <h2 className="i-intro">Hello, It's</h2>
                    <h1 className="i-name">Prabindra Sapkota</h1>
                    <br/>
                    <br/>
                    <br/>
                    <br />
                    <div className="i-title">
                        <div className="i-title-wrapper">
                            <div className="i-title-item" style={{ "--index": 1 }}>
                                Game Dev
                            </div>
                            <div className="i-title-item" style={{ "--index": 2 }}>
                                .Net Dev
                            </div>
                            <div className="i-title-item" style={{ "--index": 3 }}>
                                ReactJs Dev
                            </div>
                            <div className="i-title-item" style={{ "--index": 4 }}>
                                Unity Dev
                            </div>
                        </div>
                    </div>
                    <div className="i-scroll">
                        <span>⬇</span>
                    </div>
                </div>
            </div>
            <div className="i-right">
                <div className="i-bg"></div>
                <img src={IntroImg} alt="Intro" className="i-img" />
            </div>
        </div>
    );
};

export default Intro;
