import React from "react";
import "./about.css";
import AboutBg from "../../images/AboutBg.png";

const About = () => {
    return (
        <div id="about" className="a">
            <div className="a-left">
                <div className="a-card bg"></div>
                <div className="a-card">
                    <img src={AboutBg} alt="About Background" className="a-img" />
                </div>
            </div>
            <div className="a-right">
                <h1 className="a-title">About Me</h1>
                <p className="about-paragraph">
                    I’m <strong>Prabindra Sapkota</strong>, a passionate <strong>Game Developer</strong> specializing in <strong>Unity3D</strong> and <strong>C#</strong>.
                    I focus on creating immersive, high-quality games, from <strong>mini-games</strong> and <strong>simulation games</strong> to <strong>game systems</strong> and <strong>UI/UX optimizations</strong>.
                    I have a strong background in project management, team leadership, and mentoring junior developers, with a keen eye for performance optimization and innovative gameplay.
                </p>

                <p className="about-paragraph">
                    With a <strong>Bachelor’s in Computer Science</strong> and experience across various projects, I’m always eager to take on new challenges,
                    especially in senior-level roles that drive creative and technical growth.
                </p>

                <p className="about-paragraph">
                    When I’m not coding, I love to travel, drawing inspiration from new experiences and cultures to fuel my creativity and approach to game development.
                </p>

                <p className="a-desc">
                    If you’d like to get in touch, feel free to say hello through any social media or contact from below.
                </p>

                {/* Scroll to Contact Button */}
                <a href="#contact" className="contact-btn">
                    <i className="fa-solid fa-address-book"></i> Contact Me
                </a>
            </div>
        </div>
    );
};

export default About;
