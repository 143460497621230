import React, { useEffect, useState } from "react";
import "./mediumPosts.css";

const MediumPosts = () => {
    const [posts, setPosts] = useState([]);
    const mediumUsername = "prabindrasapkota"; // Medium username
    const mediumProfile = `https://medium.com/@${mediumUsername}`; // Profile link

    useEffect(() => {
        const fetchMediumPosts = async () => {
            try {
                const response = await fetch(
                    `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@${mediumUsername}`
                );
                const data = await response.json();
                setPosts(data.items.slice(0, 4)); // Show latest 4 posts
            } catch (error) {
                console.error("Error fetching Medium posts:", error);
            }
        };
        fetchMediumPosts();
    }, []);

    return (
        <section id="medium">
            <div>
                <center><h1 className="m-title">Latest Posts</h1></center>
            </div>

            <div className="posts-container">
                {posts.map((post, index) => (
                    <div key={index} className="post-card">
                        <h3 className="post-title">{post.title}</h3>
                        <p className="post-description">
                            {post.description.replace(/<[^>]*>/g, "").substring(0, 200)}...
                        </p>
                        <a href={post.link} target="_blank" rel="noopener noreferrer" className="read-more">
                            Learn More →
                        </a>
                    </div>
                ))}
            </div>

            <div className="button-container">
                <a href={mediumProfile} target="_blank" rel="noopener noreferrer" className="decode-btn">
                    Decode My Insights
                </a>
            </div>
        </section>
    );
};

export default MediumPosts;
