import React from "react";
import "./contact.css";
import { useContext } from "react";
import { ThemeContext } from "../../context";

const Contact = () => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    return (
        <div id="contact" className="c">
            <div className="c-bg"></div>
            <div className="c-wrapper">
                <div className="c-left">
                    <p className="c-desc">
                        <b>What's your story?</b>
                        <h1 className="c-title">Reach out on</h1>
                    </p>
                    <div className="social-network">
                        <ul className="social-icons">
                            <li>
                                <a
                                    href="https://www.linkedin.com/in/prabindrasapkota/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fa-brands fa-linkedin"></i>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://github.com/Parzival-101"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fa-brands fa-github-square"></i>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://twitter.com/Prabindra101"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fa-brands fa-twitter-square"></i>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.facebook.com/PrabindraSapkota101"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fa-brands fa-facebook-square"></i>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/prabindrasapkota/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fa-brands fa-instagram-square"></i>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="mailto:prabindrasapkota89@gmail.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fa-solid fa-envelope"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p>
                        <i>
                            Get in touch. Always available to collaborate!
                        </i>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Contact;
