import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider } from "./context";

// Disable right-click (works in both development and production)
document.addEventListener("contextmenu", (e) => e.preventDefault());

// Function to check if DevTools is open
function isDevToolsOpen() {
    const devtools = window.outerHeight - window.innerHeight > 100 || window.outerWidth - window.innerWidth > 100;
    return devtools;
}

// Function to prevent DevTools (only works in production mode)
function preventDevTools() {
    if (process.env.NODE_ENV === 'production' && isDevToolsOpen()) {
        window.location.href = 'about:blank';  // Redirect to a blank page
    }
}

// Block DevTools keyboard shortcuts (F12, Ctrl+Shift+I, etc.)
function blockDevToolsShortcuts(event) {
    const forbiddenKeys = [123, 73]; // F12 and 'I' key (for Ctrl+Shift+I)
    if (
        (event.keyCode === forbiddenKeys[0]) || // F12
        (event.keyCode === forbiddenKeys[1] && (event.ctrlKey || event.metaKey) && event.shiftKey) // Ctrl+Shift+I
    ) {
        event.preventDefault();
    }
}

// Attach event listener to block DevTools shortcuts
document.addEventListener("keydown", blockDevToolsShortcuts);

// Trigger the check before the page loads
window.onload = function () {
    preventDevTools();
};

// Render the app
ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider>
            <App />
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
