import React, { useState, useContext } from "react";
import { ThemeContext } from "./context";
import Navbar from "./components/navBar/navBar";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Intro from "./components/intro/Intro";
import ProductList from "./components/productList/productList";
import Toggle from "./components/toggle/Toggle";
import MediumPosts from "./components/mediumPosts/MediumPosts";
import YouTubeVideos from "./components/youtubeVideo/youtubeVideo";

const App = () => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleSidebar = () => {
        setIsCollapsed((prev) => !prev);
    };

    return (
        <div
            style={{
                backgroundColor: darkMode ? "#222" : "#999",
                color: darkMode ? "#fff" : "#000",
                transition: "background-color 0.3s, color 0.3s",
            }}
        >
            {/*<div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>*/}
            {/*    <button className="sidebar-toggle" onClick={toggleSidebar}>*/}
            {/*        {isCollapsed ? ">" : "<"}*/}
            {/*    </button>*/}
            {/*    */}{/*<Navbar />*/}
            {/*</div>*/}
            <div className="main-content">
                <Intro />
                <About />
                <ProductList />
                <MediumPosts />
                <YouTubeVideos />
                <Contact />
                {/*<Toggle />*/}
            </div>
        </div>
    );
};

export default App;
